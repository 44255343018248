import { useState } from 'react'
import { toast } from 'react-toastify'
import FileInput from '../../../components/FileInput'
import Input from '../../../components/Input'
import Modal from '../../../components/Modal'
import { isURL } from '../../../utils/helpers'
import './CreateDocumentModal.scss'
import TextArea from '../../../components/TextArea'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'

interface Props {
    visible: boolean
    createDocument: (name: string, url?: string, file?: File, description?: string) => void
    closeModal: () => void
}

function CreateDocumentModal(props: Props) {
    const [title, setTitle] = useState<string>('')
    const [description, setDescription] = useState<string>('')
    const [url, setUrl] = useState<string>('http://')
    const [file, setFile] = useState<File | null>(null)
    const [documentType, setDocumentType] = useState<number>(1)
    const [selectedType, setSelectedType] = useState({ id: '', text: '' })
    const documentTypeList = [
        { id: 1, text: 'Upload file' },
        { id: 2, text: 'Insert URL' },
    ]

    const isValidForm = () => {
        try {
            if (documentType === 1) {
                return title.length > 0 && file
            } else {
                return title.length > 0 && url.length > 0 && isURL(url)
            }
        } catch (error: any) {
            return false
        }
    }

    const clearForm = () => {
        setTitle('')
        setDescription('')
        setUrl('')
        setFile(null)
        setDocumentType(1)
    }

    const setUrlInput = () => (url !== 'http://' && url ? url : '')

    const clickPrimary = async () => {
        props.createDocument(title, setUrlInput(), file!, description)
        clearForm()
    }

    const onSecondary = () => {
        clearForm()
        props.closeModal()
    }

    const onChangeFile = async (file: File) => {
        try {
            if (file) {
                setFile(file)
            } /* else not needed */
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        }
    }

    return (
        <Modal
            className="create-document-modal"
            visible={props.visible}
            title="Add new document"
            primaryButtonText="Add document"
            onClickPrimary={clickPrimary}
            primaryDisabled={!isValidForm()}
            secondaryButtonText="Cancel"
            onClickSecondary={onSecondary}
        >
            <div className="modal-line">
                <Input value={title} onChange={(e) => setTitle(e.target.value)} placeholder="TITLE" required />
            </div>
            <div className="modal-line">
                <BasicDropdown
                    label="TYPE"
                    menuItems={documentTypeList}
                    onChange={(data) => {
                        setDocumentType(data.id)
                        setSelectedType(data)
                    }}
                    selectedItem={selectedType}
                />
            </div>
            {documentType === 1 && (
                <div className="modal-line">
                    <FileInput
                        fileInputText="UPLOAD DOCUMENT REVISION"
                        name={file ? file.name : 'Drag and drop file to upload or'}
                        handleChange={(e) => onChangeFile(e as File)}
                        fileTypes={['pdf', 'png', 'jpg', 'jpeg']}
                    />
                </div>
            )}
            {documentType === 2 && (
                <div className="modal-line">
                    <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder="URL" required />
                </div>
            )}

            <div className="modal-line">
                <TextArea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="DESCRIPTION" lineCount={2} />
            </div>
        </Modal>
    )
}

export default CreateDocumentModal
