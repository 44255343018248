import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { CustomPartModel } from '../../models/Db/Equipment'
import AuthService from '../../services/UiService/Auth'
import { CustomServiceModel } from '../../store/models'
import { formatQuantity } from '../../utils/helpers'
import Button, { ButtonType } from '../Button'
import CurrencyInput from '../CurrencyInput'
import Input from '../Input'
import AddMoreButton from './../AddMore/index'
import './AdditionalForm.scss'

interface Props {
    className?: string
    menuPosition?: 'up' | 'down'
    addAdditionalServiceItem: (item: CustomServiceModel) => void
    addAdditionalItem: (item: CustomPartModel) => void
}
const initialCustomPart = {
    id: '',
    manufacturer: {
        id: '',
        name: '',
    },
    part_no: '',
    qty: 1,
    name: '',
    note: '',
    price: 0,
    serial_no: '',
    equipment: '',
    attachments: [],
}
const additionalInput: {
    key: string
    label: string
    type: string
    width: string
    defaultValue?: number
    min?: number
    required?: boolean
}[] = [
    {
        key: 'equipment_name',
        label: 'EQUIPMENT',
        width: '25%',
        type: 'text',
    },
    {
        key: 'serial_no',
        label: 'SERIAL NUMBER',
        width: '25%',
        type: 'text',
    },
    {
        key: 'manufacturer',
        label: 'Manufacturer',
        width: '25%',
        type: 'text',
    },
    {
        key: 'part_no',
        label: 'PART NUMBER',
        width: '25%',
        type: 'text',
        required: true,
    },
    {
        key: 'name',
        label: 'PART NAME',
        width: '50%',
        type: 'text',
        required: true,
    },
    {
        key: 'qty',
        label: 'QTY.',
        width: '15%',
        type: 'number',
        defaultValue: 1,
        min: 1,
    },
    {
        key: 'price',
        label: 'PRICE',
        width: '15%',
        type: 'number',
        min: 1,
    },
]
const additionalServiceInput: {
    key: string
    label: string
    type: string
    width: string
    defaultValue?: number
    min?: number
    required?: boolean
}[] = [
    {
        key: 'name',
        label: 'TITLE',
        type: 'text',
        width: '80%',
        required: true,
    },
    {
        key: 'price',
        label: 'PRICE',
        width: '20%',
        type: 'number',
    },
    {
        key: 'note',
        label: 'DESCRIPTION',
        width: '80%',
        type: 'textarea',
    },
]

function AdditionalForm(props: React.PropsWithChildren<Props>) {
    const auth = new AuthService().getAuthUser()
    const [customItemInputInfo, setCustomItemInputInfo] = useState<{ isService?: boolean; visible: boolean }>({ isService: false, visible: false })
    const [additionalPart, setAdditionalPart] = React.useState<CustomPartModel>(initialCustomPart)

    const showAdditionalInput = async (isService?: boolean) => {
        await setAdditionalPart(initialCustomPart)
        setCustomItemInputInfo({ isService: isService, visible: true })
    }

    const mergeState = (state: any, newState: any) => {
        return {
            ...state,
            ...newState,
        }
    }
    const addNewPart = (value: any, key: string, isService?: boolean) => {
        if (!isService) {
            setAdditionalPart(mergeState(additionalPart, { [key]: value }))
        }
    }

    const isValidForm = () => {
        if (customItemInputInfo.isService) {
            return additionalPart.name.length > 3
        } else {
            return (additionalPart as CustomPartModel).part_no.length > 2 && additionalPart.name.length > 2
        }
    }

    const addAdditionalPart = () => {
        if (customItemInputInfo.isService) {
            const newItem: CustomServiceModel = {
                name: additionalPart.name,
                note: additionalPart.note,
                attachments: additionalPart.attachments,
                price: additionalPart.price,
                manufacturerId: auth.company.id,
                qty: 1,
            }
            props.addAdditionalServiceItem(newItem)
        } else {
            props.addAdditionalItem(additionalPart as CustomPartModel)
        }
        setAdditionalPart(initialCustomPart)
        setCustomItemInputInfo({ visible: false })
    }

    return (
        <div className={`additional-form-container ${props.className}`}>
            {customItemInputInfo.visible && (
                <div className="additional-input">
                    {(customItemInputInfo.isService ? additionalServiceInput : additionalInput).map((item, index) => {
                        const value = Object.values(additionalPart)[Object.keys(additionalPart).indexOf(item.key)]
                        if (item.key === 'manufacturer') {
                            return (
                                <div style={{ width: item.width }} className="line">
                                    <Input
                                        key={index}
                                        type={item.type}
                                        required={item.required}
                                        placeholder={item.label}
                                        value={additionalPart.manufacturer.name}
                                        onChange={(e) =>
                                            addNewPart(
                                                {
                                                    id: auth.company.id,
                                                    name: e.target.value,
                                                },
                                                item.key
                                            )
                                        }
                                        defaultValue={item.defaultValue}
                                        min={item.min}
                                    />
                                </div>
                            )
                        } else {
                            if (item.key === 'qty') {
                                return (
                                    <div style={{ width: item.width }} className="line">
                                        <Input
                                            required={item.required}
                                            placeholder={item.label}
                                            key={index}
                                            defaultValue={item.defaultValue}
                                            id={item.key}
                                            onWheel={(e) => (e.target as HTMLElement).blur()}
                                            onChange={(e) => {
                                                addNewPart(formatQuantity(e.target.value), item.key)
                                            }}
                                            value={value}
                                            type="number"
                                            min={item.min}
                                        />
                                    </div>
                                )
                            } else if (item.key === 'price') {
                                return (
                                    <div style={{ width: item.width }} className="line">
                                        <CurrencyInput
                                            required={item.required}
                                            placeholder={item.label}
                                            key={index}
                                            onChange={(price) => {
                                                addNewPart(price, item.key)
                                            }}
                                            value={value}
                                        />
                                    </div>
                                )
                            } else {
                                return (
                                    <div style={{ width: item.width }} className="line">
                                        <Input
                                            key={index}
                                            type={item.type}
                                            required={item.required}
                                            placeholder={item.label}
                                            defaultValue={item.defaultValue}
                                            value={value}
                                            onChange={(e) => addNewPart(e.target.value, item.key)}
                                        />
                                    </div>
                                )
                            }
                        }
                    })}

                    <div className="input-button-wrapper">
                        <Button disabled={!isValidForm()} onClick={addAdditionalPart} type={ButtonType.Primary}>
                            Add
                        </Button>
                        <Button
                            onClick={() => {
                                setAdditionalPart(initialCustomPart)
                                setCustomItemInputInfo({ visible: false })
                            }}
                            type={ButtonType.Primary}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </Button>
                    </div>
                </div>
            )}

            <AddMoreButton
                menuPosition={props.menuPosition}
                options={[
                    {
                        title: 'Add custom service',
                        onClick: () => {
                            showAdditionalInput(true)
                        },
                    },
                    { title: 'Add custom part', onClick: showAdditionalInput },
                ]}
            />
        </div>
    )
}

export default AdditionalForm
