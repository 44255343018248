import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ContentBox from '../../../components/ContentBox'
import ListCard from '../../../components/ListCard'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import Icons, { IconType } from '../../../Icon'
import { arraySort, getFileType, UploadFileType } from '../../../utils/helpers'
import CreateDocumentModal from './CreateDocumentModal'
import './DocumentList.scss'
import EditDocumentModal from './EditDocumentModal'
import SearchInput from '../../../components/SearchInput'
import Button from '../../../components/Button'
import EasyIcon from '../../../components/EasyIcon'
import DocumentListTable from './DocumentListTable'
import NewDocumentService from '../../../services/UiService/NewDocument'
import { CreateDocumentModel, DocumentWithEquipmentsModel, UpdateDocumentModel } from '../../../models/New/Document'
import StorageService from '../../../services/NewServices/StorageService'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import NoData from '../../../components/NoData'
import PopoverItem from '../../../components/PopoverItem'

function DocumentList() {
    const { confirm } = useContext(ConfirmationContext)
    const [document, setDocument] = useState<DocumentWithEquipmentsModel>()
    const [documents, setDocuments] = useState<DocumentWithEquipmentsModel[]>([])
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentWithEquipmentsModel[]>([])
    const [loading, setLoading] = useState<boolean>(true)
    const [createDocumentModalVisible, setCreateDocumentModalVisible] = useState<boolean>(false)
    const [editDocumentModalVisible, setEditDocumentModalVisible] = useState<boolean>(false)
    const [selectedView, setSelectedView] = useState<boolean>(false)
    const [searchKey, setSearchKey] = useState<string>('')
    const page: number = 0
    const size: number = 1000
    const navigate = useNavigate()

    const breadcrumb: BreadcrumbModel[] = [
        {
            name: 'Documents',
        },
    ]

    useEffect(() => {
        getDocuments()
    }, [])

    async function getDocuments() {
        try {
            setLoading(true)
            const documents = await NewDocumentService.getDocuments({ page, size })
            setDocuments(documents.content)
            setFilteredDocuments(documents.content)
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }
    async function createDocumentHandler(name: string, url?: string, file?: File, description?: string) {
        try {
            setLoading(true)
            let fileUrl = ''
            let fileType = ''
            if (file) {
                const uploadFileService = new StorageService()
                const fileInfo = await uploadFileService.upload(file)
                fileUrl = fileInfo.url
                fileType = file.type
            } /* else not needed */
            if (url) {
                fileType = 'url'
                fileUrl = url
            } /* else not needed */

            const toCreate: CreateDocumentModel = {
                name: name,
                type: fileType,
                category: '',
                url: fileUrl,
                description: description,
                isExternal: false,
            }

            await NewDocumentService.createDocument(toCreate)
            await getDocuments()
            // TODO: refactoring
            // setDocuments([...documents, result])
            toast.success('Successfully added!')
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setCreateDocumentModalVisible(false)
            setLoading(false)
        }
    }

    async function editDocumentHandler(documentId: string, name: string, url?: string, file?: File, description?: string) {
        try {
            setLoading(true)
            let fileUrl = ''
            let fileType = 'url'
            if (file) {
                const uploadFileService = new StorageService()
                const fileInfo = await uploadFileService.upload(file)
                fileUrl = fileInfo.url
                fileType = file.type
            } /* else not needed */
            if (url) {
                fileUrl = url
            } /* else not needed */

            const toUpdate: UpdateDocumentModel = {
                name: name,
                url: fileUrl,
                type: fileType,
                description: description,
            }

            const result: any = await NewDocumentService.updateDocument(documentId, toUpdate)
            const updated: DocumentWithEquipmentsModel[] = documents.filter((item) => item.id !== documentId)
            setDocuments([...updated, result])
            toast.success('Successfully edited!')
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setEditDocumentModalVisible(false)
            setLoading(false)
            setDocument(undefined)
        }
    }

    const onHandleSearch = (value: DocumentWithEquipmentsModel[]) => {
        setFilteredDocuments(value)
    }

    const openDeleteDocumentModal = (documentId: string) => {
        confirm({
            modalClassName: 'delete-document-modal',
            modalTitle: 'Delete document?',
            titleColor: '#D84315',
            primaryButtonText: 'Cancel',
            doesPrimaryButtonSubmit: false,
            secondaryButtonText: 'Delete',
            successText: '"Successfully deleted!"',
            func: async (successCallback, errCallback) => {
                try {
                    setLoading(true)
                    await NewDocumentService.deleteDocument({ documentId })
                    const result: DocumentWithEquipmentsModel[] = documents.filter((item) => item.id !== documentId)
                    setDocuments(result)
                    successCallback()
                } catch (error: any) {
                    errCallback(error)
                } finally {
                    setLoading(false)
                    setDocument(undefined)
                }
            },
            contentNode: (
                <div className="modal-line">
                    <span className="warning-text">
                        If you are deleting a document linked to one or more equipment, your customers who have access to these equipment will no longer see this document.
                        <br />
                        <br />
                        Are you sure you would like to proceed?
                    </span>
                </div>
            ),
        })
    }

    return (
        <>
            {documents.length === 0 ? (
                <>
                    {loading && <Loading />}

                    <NoData
                        breadcrumb={breadcrumb}
                        pageTitle="Documents"
                        title="You currently do not have any documents on Monittor.  Start by adding one now!"
                        handleClick={() => setCreateDocumentModalVisible(true)}
                    />
                </>
            ) : (
                <PageWrapper breadcrumb={breadcrumb}>
                    <ContentBox
                        title="Documents"
                        className="document-list-page"
                        headerIcon={<EasyIcon icon={IconType.Document} />}
                        headerRight={
                            <div className="document-header-right">
                                <SearchInput data={documents} handleSearch={onHandleSearch} searchKeys={['id', 'name', 'type', 'description']} setDefaultSearch={searchKey} />

                                <PopoverItem popoverContent={selectedView ? 'List View' : 'Card View'}>
                                    <Button className="toggle-button" onClick={() => setSelectedView(!selectedView)}>
                                        {!selectedView ? <Icons type={IconType.Menus} /> : <Icons type={IconType.CardView} />}
                                    </Button>
                                </PopoverItem>
                                <PopoverItem popoverContent="Add new">
                                    <Button className="create-document-button" onClick={() => setCreateDocumentModalVisible(true)}>
                                        <Icons type={IconType.PlusCircle} />
                                    </Button>
                                </PopoverItem>
                            </div>
                        }
                    >
                        {loading && <Loading />}
                        {documents &&
                            (selectedView ? (
                                <ListCard
                                    data={arraySort(filteredDocuments, 'name').map((d) => {
                                        return {
                                            isClickable: true,
                                            onClick: () => {
                                                window.open(d.url)
                                            },
                                            buttons: [
                                                {
                                                    icon: IconType.Show,
                                                    onClick: () => {
                                                        // VIEW
                                                        const fileType = getFileType(d.file)
                                                        if (fileType === UploadFileType.Url) {
                                                            window.open(d.file)
                                                        } else {
                                                            navigate('/document/' + d.id)
                                                        }
                                                    },
                                                },
                                                {
                                                    icon: IconType.Pen,
                                                    onClick: () => {
                                                        // EDIT
                                                        setDocument(d)
                                                        setEditDocumentModalVisible(true)
                                                    },
                                                },
                                                {
                                                    icon: IconType.NewTrash,
                                                    onClick: () => {
                                                        // DELETE
                                                        openDeleteDocumentModal(d.id)
                                                    },
                                                },
                                            ],
                                            children: <span className="document-list-title">{d.name}</span>,
                                        }
                                    })}
                                />
                            ) : (
                                <DocumentListTable
                                    setSearch={(e) => {
                                        if (searchKey === e) {
                                            setSearchKey('')
                                        } else {
                                            setSearchKey(e)
                                        }
                                    }}
                                    data={filteredDocuments.map((d) => {
                                        return {
                                            name: d.name,
                                            type: d.type,
                                            onClick: () => {
                                                window.open(d.url)
                                            },
                                            button: [
                                                {
                                                    icon: IconType.Pen,
                                                    onClick: () => {
                                                        // EDIT
                                                        setDocument(d)
                                                        setEditDocumentModalVisible(true)
                                                    },
                                                    tooltip: 'Edit',
                                                },
                                                {
                                                    icon: IconType.NewTrash,
                                                    onClick: () => {
                                                        // DELETE
                                                        openDeleteDocumentModal(d.id)
                                                    },
                                                    tooltip: 'Delete',
                                                },
                                            ],
                                        }
                                    })}
                                />
                            ))}
                    </ContentBox>
                </PageWrapper>
            )}
            {document && (
                <EditDocumentModal
                    document={document}
                    visible={editDocumentModalVisible}
                    closeModal={() => {
                        setEditDocumentModalVisible(false)
                        setDocument(undefined)
                    }}
                    editDocument={editDocumentHandler}
                />
            )}
            <CreateDocumentModal visible={createDocumentModalVisible} closeModal={() => setCreateDocumentModalVisible(false)} createDocument={createDocumentHandler} />
        </>
    )
}

export default DocumentList
