/* eslint-disable react-hooks/exhaustive-deps */
import Panzoom, { PanzoomObject } from '@panzoom/panzoom'
import { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { toast } from 'react-toastify'
import { BreadcrumbModel } from '../../../components/Breadcrumb'
import ContentBox from '../../../components/ContentBox'
import Loading from '../../../components/Loading'
import PageWrapper from '../../PageWrapper'
import PdfViewer from '../../../components/PdfViewer'
import Tab from '../../../components/Tab'
import ThreeDotButton, { ActionModel } from '../../../components/ThreeDotButton'
import ZoomButton from '../../../components/ZoomButton'
import Icons, { IconType } from '../../../Icon'
import { DrawingType, EquipmentCartModel } from '../../../models/Ui/Equipment'
import { TabItem, Tabs } from '../../../models/Ui/Tab'
import { arraySort, csvToJson, getFileType, priceFormatter, standardDate, UploadFileType } from '../../../utils/helpers'
import EditEquipmentPartStoreModal from './EditEquipmentPartStoreModal'
import EquipmentDetailsTab from './EquipmentDetailsTab'
import PartsTable from '../EquipmentDetail/Tables/PartsTable'
import PartialPartsTable from '../EquipmentDetail/Tables/PartialPartsTable'
import DocumentListTable from '../EquipmentDetail/Tables/DocumentListTable'
import TechnicianView from '../TechnicianView'
import EquipmentService from '../../../services/UiService/Equipment'
import {
    CreatePartListModel,
    CustomEquipmentListModel,
    EquipmentModel,
    EquipmentPartsListModel,
    NewUpdateEquipmentModel,
    PartsModel,
    TreeViewModel,
    UpdateCustomEquipmentModel,
} from '../../../models/New/Equipment'
import { DocumentModel } from '../../../models/New/Document'
import { CreateTagModel, TagModel } from '../../../models/New/Tag'
import TagService from './../../../services/UiService/Tag'
import StorageService from '../../../services/NewServices/StorageService'
import LinkDocumentModal from './../modals/LinkDocument/index'
import PartListService from '../../../services/UiService/PartList'
import OrderService from '../../../services/UiService/Order'
import { OrderModel } from '../../../models/New/Order'
import { PartModel } from '../../../models/Db/Equipment'
import { UserRoleType } from '../../../models/Ui/UserRoleType'
import { UserModel } from '../../../models/Ui/User'
import UserService from '../../../services/UiService/User'
import SearchInput from '../../../components/SearchInput'
import Warranty from '../Warranty'
import OrderListTable from '../../../components/OrderTableList'
import EditPartModal from './../EquipmentDetail/Modals/Equipment/EditPartModal/index'
import Button, { ButtonType } from '../../../components/Button'
import PopoverItem from './../../../components/PopoverItem/index'
import CreatePartModal from './../EquipmentDetail/Modals/Equipment/CreatePartModal/index'
import { ConfirmationContext } from '../../../store/ConfirmationContext'
import PartService from '../../../services/UiService/Part'
import { useSearchParams } from 'react-router-dom'
import { CommonActionTypes, EquipmentActionTypes } from '../../../models/Ui/Common'
import PushEquipmentToCustomModal from '../EquipmentDetail/Modals/Equipment/PushEquipmentToCustomModal'
import { ICartInformation, ISavedCartInformation, TechnicianViewType } from '../../../store/models'
import { CheckoutContext } from '../../../store/NewCheckoutContext/CheckoutContext'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ActivityListTable, {
    activityTableColumns,
    ActivityTableDataModel,
    assigneeColorSetter,
    assigneeFirstNameSetter,
    assigneeShortNameSetter,
    AssigneeWithColorModel,
    filterAssignees,
    HeadCell,
    HeadCellType,
    setAssigneeInputPlaceholder,
    useUniqueAssigneesWithColorsSetter,
} from '../ActivityList/ActivityListTable'
import ActivityService from '../../../services/UiService/Activity'
import { ActivityModel, ActivityTypes } from '../../../models/Ui/Activity'
import EditCustomEquipmentModal from './Modals/EditCustomEquipmentModal/EditCustomEquipmentModal'
import { DueDateFilter, FilterKeys, FilterState, FilterType, SortByFilter, SortByType, StatusFilter, StatusFilterType } from '../ActivityList'
import PartialUpdateBox from '../ActivityList/PartialUpdateBox'
import './EquipmentActivity.scss'
import NewNotepad from '../../../components/NewNotepad'
import CheckBoxDropdownInput from '../../../components/CheckBoxDropdownInput'
import { Switch, Tooltip } from '@mui/material'
import NoDataInfo from '../../../components/NoDataInfo'
import BasicDropdown from '../../../components/Dropdown/BasicDropdown'
import CreateAssemblyModal from './Modals/CreateAssemblyModal'
import EquipmentTitle from '../../../components/EquipmentDetailPageTitle'
import TreeView from '../../../components/NewTreeView/TreeView'
import TreeViewButton from '../../../components/NewTreeView/TreeViewButton/TreeViewButton'
import EditCustomEquipmentAssemblyModal from './Modals/EditAssemblyModal/EditCustomEquipmentAssemblyModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import EquipmentCreateDocumentModal from '../../../components/EquipmentCreateDocumentModal'
import UpdateActivityPopupSidebar from '../ActivityList/Modals/UpdateActivityPopupSidebar'
import AddActivityPopupSidebar from '../ActivityList/Modals/AddActivityPopupSidebar'
import DrawingDashboard from '../../../components/DrawingDashboard/DrawingDashboard'
import UploadDrawingPartsModal from '../EquipmentDetail/Modals/Equipment/UploadDrawingPartsModal'
import EditPartsListModal from './Modals/EditPartsList'

const tabItems: TabItem[] = [
    {
        id: Tabs.DrawingAndParts,
        name: 'Drawing and Parts',
        customIcon: <Icons type={IconType.Parts} />,
    },
    {
        id: Tabs.Details,
        name: 'Details',
        customIcon: <Icons type={IconType.Info} />,
    },
    {
        id: Tabs.Activities,
        name: 'Activities',
        customIcon: <Icons type={IconType.Activities} />,
    },
    {
        id: Tabs.Documents,
        name: 'Documents',
        customIcon: <Icons type={IconType.Folder} />,
    },
    // {
    //     id: Tabs.Orders,
    //     name: 'Orders',
    //     customIcon: <Icons type={IconType.OrderBag} />,
    // },
]

const activityTabItems: TabItem[] = [
    {
        id: Tabs.All,
        name: 'All',
    },
    {
        id: Tabs.Service,
        name: 'Service',
        customIcon: <Icons type={IconType.Maintenance} />,
    },
    {
        id: Tabs.ProductReplenishment,
        name: 'Products',
        customIcon: <Icons type={IconType.Product} />,
    },
    {
        id: Tabs.Activities,
        name: 'Tickets',
        customIcon: <Icons type={IconType.Ticket} />,
    },
]

export interface ParentModel {
    id: string
    text: string
}

interface EditModalInfoModel {
    visible: boolean
    data: PartsModel | undefined
}

interface CreateModalInfoModel {
    visible: boolean
    data: EquipmentPartsListModel | undefined
}

function CustomerEquipmentDetailForManufacturer() {
    const { confirm } = useContext(ConfirmationContext)
    let { customerId, equipmentId, assemblyId } = useParams()
    const [user, setUser] = useState<UserModel>()
    const [equipment, setEquipment] = useState<EquipmentModel>({} as EquipmentModel)
    const [loading, setLoading] = useState<boolean>(true)
    const [drawingType, setDrawingType] = useState<DrawingType>(DrawingType.Image)
    const [activeTab, setActiveTab] = useState<number>(tabItems[0].id)
    const [activePartListIndex, setActivePartListIndex] = useState<number>(0)
    const [filteredData, setFilteredData] = useState([] as EquipmentModel[] | any[])
    const [searchedData, setSearchedData] = useState([] as EquipmentModel[] | any[])
    const imageContainer = useRef<HTMLDivElement>(null)
    const imageContainerDocument = useRef<HTMLDivElement>(null)
    const panzoom = useRef<PanzoomObject | null>(null)
    const panzoomDocument = useRef<PanzoomObject | null>(null)
    const prevAssemblyIdRef = useRef<string>()
    const [documentDetailVisible, setDocumentDetailVisible] = useState<boolean>(false)
    const [fileType] = useState<UploadFileType | undefined>(UploadFileType.Image)
    const [equipmentDocument] = useState<DocumentModel>()
    const [orders, setOrders] = useState<OrderModel[]>([])
    const [documents, setDocuments] = useState<DocumentModel[]>([])
    const noteRef = useRef<string>()
    const selectedEquipmentId = useRef<string>('')
    const [editEquipmentPartsModalVisible, setEditEquipmentPartsModalVisible] = useState<boolean>(false)
    const [linkDocumentModalVisible, setLinkDocumentModalVisible] = useState<boolean>(false)
    const [searchKey, setSearchKey] = useState<string>('')
    const [createPartModalInfo, setCreatePartModalInfo] = useState<CreateModalInfoModel>({
        visible: false,
        data: undefined,
    })
    const [editPartModalInfo, setEditPartModalInfo] = useState<EditModalInfoModel>({ visible: false, data: undefined })
    const newPartList = useRef<CreatePartListModel | any>()
    const checkoutContext = useContext(CheckoutContext)
    const navigate = useNavigate()
    let [searchParams, setSearchParams] = useSearchParams()
    const parentRef = useRef<ParentModel>()
    const [assembly] = useState<EquipmentModel>()
    const [editEquipmentDrawingPartsModalVisible, setEditEquipmentDrawingPartsModalVisible] = useState<boolean>(false)
    const [editEquipmentModalVisible, setEditEquipmentModalVisible] = useState<boolean>(false)
    const [editAssemblyModalVisible, setEditAssemblyModalVisible] = useState<boolean>(false)
    const [createAssemblyModalVisible, setCreateAssemblyModalVisible] = useState<boolean>(false)
    const [pushEquipmentToCustomModalVisible, setPushEquipmentToCustomModalVisible] = useState<boolean>(false)
    const [editAssemblyDrawingPartsModalVisible, setEditAssemblyDrawingPartsModalVisible] = useState<boolean>(false)
    const [, setCustomInstallations] = useState<CustomEquipmentListModel[]>([])
    const [activities, setActivities] = useState<ActivityModel[]>([])
    const [showFilter, setShowFilter] = useState<boolean>(false)

    const [filteredActivitiesData, setFilteredActivitiesData] = useState<ActivityModel[]>([])
    const [partialUpdateModalVisible, setPartialUpdateModalVisible] = useState(false)
    const [selectedActivtyIds, setselectedActivtyIds] = useState<{ id: string; groupId: string }>({ id: '', groupId: '' })
    const [updateModalVisible, setUpdateModalVisible] = useState<boolean>(false)
    const [createActivityModalVisible, setCreateActivityModalVisible] = useState(false)
    const [uniqueAssigneesWithColors, setUniqueAssigneesWithColors] = useState<AssigneeWithColorModel[]>([])
    const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<string[]>([])
    const [showAllAsignees, setShowAllAsignees] = useState(true)
    const [viewHistoricalOnes, setViewHistoricalOnes] = useState(false)
    const [activeActivityTab, setActiveActivityTab] = useState<number>(activityTabItems[0].id)
    const [treeViewVisible, setTreeViewVisible] = useState<boolean>(false)
    const [filteredDocuments, setFilteredDocuments] = useState<DocumentModel[]>([])
    const [filteredOrders, setFilteredOrders] = useState<OrderModel[]>([])
    const [createDocumentModalVisible, setCreateDocumentModalVisible] = useState<boolean>(false)
    const [isDrawingTabHortizalRow, setIsDrawingTabHortizalRow] = useState<boolean>(false)
    const [selectedProductId, setSelectedProductId] = useState<string>('')
    const [selectedActivityEquipmentId, setSelectedActivityEquipmentId] = useState<string>('')
    const [isLinkedTemplate, setIsLinkedTemplate] = useState<boolean>(true)
    const [parentList, setParentList] = useState<ParentModel[]>([])
    const [selectedEquipmentTreeInfo, setSelectedEquipmentTreeInfo] = useState<TreeViewModel>({} as TreeViewModel)
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(searchParams?.get('selectedImageIndex') ? Number(searchParams?.get('selectedImageIndex')) : 0)
    const [editPartsListNameModalVisible, setEditPartsListNameModalVisible] = useState<boolean>(false)

    const onClickPreviousImage = () => {
        if (selectedImageIndex !== 0 && selectedImageIndex > 0) {
            setSelectedImageIndex((prevState) => prevState - 1)
            searchParams.set('selectedImageIndex', (selectedImageIndex - 1).toString())
            setSearchParams(searchParams)
        }
    }

    const onClickNextImage = () => {
        if (equipment.drawings.length - 1 !== selectedImageIndex && selectedImageIndex < equipment.drawings.length - 1) {
            setSelectedImageIndex((prevState) => prevState + 1)
            searchParams.set('selectedImageIndex', (selectedImageIndex + 1).toString())
            setSearchParams(searchParams)
        }
    }

    let parentModels: ParentModel[] = []

    useUniqueAssigneesWithColorsSetter(activities, setUniqueAssigneesWithColors)

    useEffect(() => {
        setSelectedAssigneeIds(uniqueAssigneesWithColors.map((child) => child.assigneeId))
    }, [uniqueAssigneesWithColors])

    const handleSearchedActivitiesData = (data: ActivityModel[]) => {
        setFilteredActivitiesData(data)
    }

    const onDocumentsHandleSearch = (data: DocumentModel[]) => setFilteredDocuments(data)
    const onOrdersHandleSearch = (data: OrderModel[]) => setFilteredOrders(data)

    dayjs.extend(localizedFormat)

    const dueDateFilterTypes: DueDateFilter[] = [
        { id: ' ', text: 'All Time' },
        {
            id: dayjs(),
            text: 'Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'day'),
            text: 'Before Today',
            key: 'day',
        },
        {
            id: dayjs().subtract(1, 'week'),
            text: 'Last Week',
            key: 'week',
        },
        {
            id: dayjs().add(1, 'week'),
            text: 'Next Week',
            key: 'week',
        },
        {
            id: dayjs(),
            text: 'This Week',
            key: 'week',
        },
        {
            id: dayjs().subtract(1, 'month'),
            text: 'Last Month',
            key: 'month',
        },
        {
            id: dayjs().add(1, 'month'),
            text: 'Next Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Month',
            key: 'month',
        },
        {
            id: dayjs(),
            text: 'This Year',
            key: 'year',
        },
    ]
    const statusFilterTypes: StatusFilter[] = [
        { id: ' ', text: 'All' },
        { id: 'Open', text: 'Open', type: StatusFilterType.Open },
        { id: 'In Progress', text: 'In Progress', type: StatusFilterType.InProgress },
        { id: 'On Hold', text: 'On Hold', type: StatusFilterType.OnHold },
        { id: 'Closed', text: 'Closed', type: StatusFilterType.Closed },
    ]

    const sortByFilterOptions: SortByFilter[] = [
        { id: 'None', text: 'None', type: SortByType.None },
        { id: 'Product', text: 'Product', type: SortByType.Product },
        { id: 'Equipment', text: 'Equipment', type: SortByType.Equipment },
    ]

    const initialFilter: FilterState = {
        dueDate: { ...dueDateFilterTypes[0] } as DueDateFilter,
        status: { ...statusFilterTypes[0] } as FilterType,
        sortBy: sortByFilterOptions[0],
    }
    const [filterState, setFilterState] = useState<FilterState>(initialFilter)

    const setFilter = (newState: any, key: FilterKeys) =>
        setFilterState((prevState) => ({
            ...prevState,
            [key]: newState,
        }))

    const toggleFilterMenu = () => {
        setFilterState(initialFilter)
        setShowFilter(!showFilter)
    }

    const getEquipmentCallback = useCallback(async () => {
        try {
            onLoadingCallback()
            const rootEquipmentResponse = await EquipmentService.getParentEquipmentTreeView(equipmentId!, assemblyId ?? equipmentId!)
            noteRef.current = rootEquipmentResponse.notes
            if (rootEquipmentResponse.drawingUrl && rootEquipmentResponse.drawingUrl.length > 0) {
                setDrawingType(new URL(rootEquipmentResponse!.drawingUrl!).pathname.toLowerCase().endsWith('.pdf') ? DrawingType.Pdf : DrawingType.Image)
            } /* else not needed */

            setEquipment(rootEquipmentResponse)
            setDocuments(rootEquipmentResponse.documents)
        } catch (e: any) {
            console.error(e)
            toast.error(e)
        } finally {
            onCompletedCallback()
        }
    }, [assemblyId, equipmentId])

    const purgeAll = (object: TreeViewModel) => {
        if (!object?.isTemplate) {
            parentModels.push({
                text: object.name,
                id: object.id,
            })
        }

        if (object.children && !object.isTemplate) {
            for (const child of object.children) {
                purgeAll(child)
            }
        }
        return parentModels
    }

    useEffect(() => {
        if (equipment?.tree) {
            const selectEquipmentId = assemblyId ?? equipmentId
            const parentList = purgeAll(equipment.tree)
            setIsLinkedTemplate(!parentList.find((parent) => parent.id === selectEquipmentId))
            setParentList(parentList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipment.tree, assemblyId, equipmentId])

    const getCustomEquipmentCallback = useCallback(async () => {
        try {
            const response = await EquipmentService.getCustomsForTemplate(assemblyId ?? equipmentId!)
            setCustomInstallations(response)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [equipmentId, assemblyId])

    const onLoadingCallback = useCallback(() => {
        setLoading(true)
    }, [])
    const onCompletedCallback = useCallback(() => {
        setLoading(false)
    }, [])
    const onSuccessCallback = useCallback(
        async (action: EquipmentActionTypes, data?: NewUpdateEquipmentModel | any) => {
            switch (action) {
                case EquipmentActionTypes.UPDATE_EQUIPMENT:
                    if (equipment && data) {
                        delete (data as any).id // Added to prevent wrong equipment id bug!
                        setEquipment({ ...equipment, ...data })
                    } /* else not needed */
                    break
                case EquipmentActionTypes.CREATE_EQUIPMENT_TAG:
                    if (equipment && data) {
                        setEquipment((prevState: any) => ({
                            ...prevState,
                            tags: equipment.tags.concat([data]),
                        }))
                    } /* else not needed */
                    break
                case EquipmentActionTypes.DELETE_EQUIPMENT_TAG:
                    if (equipment && data) {
                        delete (data as any).id // Added to prevent wrong equipment id bug!
                        const newData = equipment.tags.filter((item: TagModel) => item.name !== data.name)
                        setEquipment((prevState: any) => ({
                            ...prevState,
                            tags: newData,
                        }))
                    } /* else not needed */
                    break
                case EquipmentActionTypes.UPDATE_EQUIPMENT_IMG:
                    setLoading(true)
                    await getEquipmentCallback()
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_EQUIPMENT_DOCUMENT:
                    setLoading(true)
                    await getEquipmentCallback()
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_ASSEMBLY:
                    await getEquipmentCallback()
                    break
                case EquipmentActionTypes.UPDATE:
                    await getEquipmentCallback()
                    break
                case EquipmentActionTypes.LINK_DOCUMENTS:
                    setLoading(true)
                    // if (assembly) {
                    //   await getAssemblyCallback()
                    // } else {
                    await getEquipmentCallback()
                    // }
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_CUSTOM_EQUIPMENT:
                    await getCustomEquipmentCallback()
                    break
                case EquipmentActionTypes.DELETE_EQUIPMENT:
                    setLoading(false)
                    break
                case EquipmentActionTypes.UPDATE_MAPPING:
                    await getEquipmentCallback()
                    break
            }
        },
        [equipment, getCustomEquipmentCallback, getEquipmentCallback]
    )
    const onErrorCallback = useCallback((error: any) => {
        console.error(error)
        toast.error(error.message)
    }, [])

    useEffect(() => {
        const activeOne = searchParams?.get('activeTab')
        setActiveTab(Number(activeOne) || Tabs.Details)
    }, [])

    const treeviewHeaderActionButtons = [
        {
            icon: IconType.BoldPlus,
            popoverContent: 'Add sub-assembly',
            onClick: () => setCreateAssemblyModalVisible(true),
        },
        {
            icon: IconType.Close,
            popoverContent: 'Hide sub-assemblies',
            onClick: () => setTreeViewVisible(false),
        },
    ]

    const rightMenuActionList: ActionModel[] = [
        {
            text: 'Edit',
            onClick: () => setEditEquipmentModalVisible(true),
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
        {
            text: 'View as customer',
            onClick: () => {
                checkoutContext.handleCartInformation({
                    technicianViewType: TechnicianViewType.ViewAs,
                    purchaseForCustomerId: customerId! || equipment?.customerId!,
                    purchaseForCustomerName: equipment?.customer?.name,
                } as ICartInformation)

                checkoutContext.setSavedCartInfo({
                    name: `Saved cart ${standardDate(new Date())}`,
                } as ISavedCartInformation)
            },
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
        {
            text: 'Purchase on behalf of customer',
            onClick: () => {
                checkoutContext.handleCartInformation({
                    technicianViewType: TechnicianViewType.PurchaseAs,
                    purchaseForCustomerId: customerId || equipment?.customerId,
                    purchaseForCustomerName: equipment?.customer?.name,
                } as ICartInformation)

                checkoutContext.setSavedCartInfo({
                    name: `Saved cart ${standardDate(new Date())}`,
                } as ISavedCartInformation)
            },
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
        {
            text: 'Delete',
            onClick: () => {
                confirm({
                    modalClassName: 'delete-equipment-modal',
                    modalTitle: 'Delete equipment?',
                    primaryButtonText: 'Delete',
                    func: async (successCallback, errCallback) => {
                        try {
                            onLoadingCallback()
                            await EquipmentService.delete(equipment!.id)
                            successCallback()
                            navigate('/equipments')
                        } catch (error: any) {
                            errCallback(error)
                            onErrorCallback(error)
                        } finally {
                            onCompletedCallback()
                        }
                    },
                    contentNode: (
                        <Fragment>
                            <div className="modal-line">Are you sure you would like to proceed?</div>
                        </Fragment>
                    ),
                })
            },
            permissions: [UserRoleType.Admin, UserRoleType.Technician],
        },
    ].filter((item) => user?.role && item.permissions?.includes(user.role))

    useEffect(() => {
        if (panzoom.current === null && imageContainer.current !== null) {
            panzoom.current = Panzoom(imageContainer.current, { startScale: '1' })
        } /* else not needed */

        if (documentDetailVisible && panzoomDocument.current === null && imageContainerDocument.current !== null) {
            panzoomDocument.current = Panzoom(imageContainerDocument.current, { startScale: '1' })
        } /* else not needed */
    })

    if (assemblyId !== prevAssemblyIdRef.current && activePartListIndex !== 0) {
        prevAssemblyIdRef.current = assemblyId
        setActivePartListIndex(0)
    } /* else not needed */

    const breadcrumbForCustomer: BreadcrumbModel[] = [
        {
            name: 'Customers',
            url: '/customers',
        },
        {
            name: equipment?.customer?.name,
            url: '/customer/' + customerId,
        },
        {
            name: 'Equipment',
            url: '/customer/' + customerId,
        },
        {
            name: equipment?.name!,
            url: '/customer/' + customerId + '/equipment/' + equipment?.id,
        },
    ]
    const breadcrumbForCustomEquipment: BreadcrumbModel[] = [
        {
            name: 'Equipment (Custom Installations)',
            url: '/equipments',
        },
        {
            name: equipment?.name!,
            url: '/custom-equipment/' + equipment?.id,
        },
    ]

    const getBreadcrumbTabName = () => {
        switch (activeTab) {
            case Tabs.Details:
                return 'Details'
            case Tabs.DrawingAndParts:
                return 'Drawing and Parts'
            case Tabs.Documents:
                return 'Documents'
            case Tabs.Products:
                return 'Products'
            case Tabs.Orders:
                return 'Orders'
            default:
                return ''
        }
    }

    breadcrumbForCustomer.push({
        name: getBreadcrumbTabName(),
    })
    breadcrumbForCustomEquipment.push({
        name: getBreadcrumbTabName(),
    })

    if (equipmentDocument && documentDetailVisible) {
        breadcrumbForCustomer.push({
            name: equipmentDocument.name,
            url: '/customer/' + customerId + '/equipment/' + equipment?.id,
        })
    }

    async function loadComponent() {
        try {
            setLoading(true)
            // await getManufacturer()
            await getEquipmentCallback()
            // await getAssembliesCallback()
            await getUserCallback()
            await getOrders()
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (equipmentId) {
            loadComponent()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipmentId, assemblyId])

    async function getOrders() {
        try {
            const orders = await OrderService.getEquipmentOrders(assemblyId ?? equipmentId!)
            setOrders(arraySort(orders, 'creationDate', 'descending'))
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        }
    }

    const getUserCallback = useCallback(async () => {
        try {
            const userService = new UserService()
            const userResponse = await userService.get()
            setUser(userResponse)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        }
    }, [])

    const getSearchedData = useCallback(async () => {
        let searchedArray: any = []
        if (equipment) {
            for (let i = 0; i < equipment?.partLists?.length; i++) {
                const newData = equipment.partLists[i]?.parts.map((item: PartsModel) => {
                    return { ...item, listName: equipment.partLists[i]?.name }
                })
                searchedArray = searchedArray.concat(newData)
            }
        }
        setSearchedData(searchedArray)
    }, [equipment])

    useEffect(() => {
        if (equipment && equipment.partLists && equipment.partLists.length > 0) {
            setFilteredData(equipment.partLists[activePartListIndex]?.parts)
            getSearchedData()
        }
    }, [activePartListIndex, equipment, getSearchedData])

    async function linkDocumentHandler(documentIds: string[]) {
        try {
            setLoading(true)
            await EquipmentService.linkDocument(assemblyId ?? equipmentId!, documentIds)
            await loadComponent()
            setDocumentDetailVisible(false)
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLinkDocumentModalVisible(false)
            setLoading(false)
        }
    }

    async function saveNoteHandler(data: UpdateCustomEquipmentModel) {
        try {
            await EquipmentService.updateCustomEquipment(assemblyId! ?? equipmentId!, { notes: data.notes })
        } catch (error: any) {
            console.error(error)
            toast.error(error)
            throw new Error(error)
        }
    }

    async function onTagsUpdateHandler(id: string, name: string, updateType: string) {
        const tagService = new TagService()
        try {
            setLoading(true)
            if (updateType === 'CREATE_TAG') {
                const newData: CreateTagModel = {
                    name: name,
                    equipmentId: id,
                }

                await tagService.createTag(newData)
            } else if (updateType === 'DELETE_TAG') {
                await tagService.delete(id)
            }
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            await getEquipmentCallback()
            setLoading(false)
        }
    }

    const updateCustomEquipmentPartStoreHandler = async (
        customEquipmentId: string,
        updatedCustomEquipment: EquipmentModel,
        drawing: File,
        partsList: EquipmentPartsListModel[],
        csvFiles: { customName: string; file: File; isRemoved: boolean }[],
        isChange: { file: boolean; pdfPreview: boolean; csv: boolean; fileNames: boolean }
    ) => {
        try {
            // const customEquipmentService = new CustomEquipmentService()
            let updatedEquipment: EquipmentModel = { ...updatedCustomEquipment }
            const uploadFileService = new StorageService()

            setLoading(true)

            if (!isChange.csv && !isChange.file && !isChange.fileNames) {
                throw new Error('Nothing changed!')
            } /* else not needed */

            if (isChange.csv) {
                await new Promise((resolve, reject) => {
                    for (let index = 0; index < csvFiles.filter((csvFile) => !csvFile.isRemoved).length; index++) {
                        csvToJson(csvFiles[index].file, (json) => {
                            if (json[0]['Description'] && json[0]['Part number'] && json[0]['Qty.'] && json[0]['No.']) {
                                const newParts: PartsModel[] = json
                                    .map((p: any, index: number) => {
                                        if (
                                            p['No.'] !== undefined &&
                                            p['No.'] !== '' &&
                                            p['Qty.'] !== undefined &&
                                            p['Qty.'] !== '' &&
                                            p['Description'] !== undefined &&
                                            p['Description'] !== '' &&
                                            p['Part number'] !== undefined &&
                                            p['Part number'] !== ''
                                        ) {
                                            if (p['Price'] && p['Price'] !== '' && !isNaN(+p['Price'].replace('$', '').replaceAll(',', ''))) {
                                                return {
                                                    index,
                                                    no: p['No.'],
                                                    qty: p['Qty.'],
                                                    description: p['Description'],
                                                    part_no: p['Part number'],
                                                    price: +p['Price'].replace('$', '').replaceAll(',', ''),
                                                }
                                            } else {
                                                return {
                                                    index,
                                                    no: p['No.'],
                                                    qty: p['Qty.'],
                                                    description: p['Description'],
                                                    part_no: p['Part number'],
                                                }
                                            }
                                        } else {
                                            return null
                                        }
                                    })
                                    .filter((part: PartsModel) => part !== null)

                                newPartList.current = {
                                    name: csvFiles[0].file.name,
                                    parts: newParts,
                                }
                                resolve(partsList)
                            } else {
                                reject()
                                toast('CSV file not valid!', {
                                    type: toast.TYPE.ERROR,
                                })
                                throw new Error('CSV file not valid!')
                            }
                        })
                    }
                }).catch((error: any) => {
                    throw error
                })
            } /* else not needed */

            if (isChange.file) {
                await uploadFileService.upload(drawing).then((file) => {
                    updatedEquipment = {
                        ...updatedEquipment,
                        drawingUrl: file.url,
                    }
                })
            } /* else not needed */

            if (isChange.pdfPreview) {
                await uploadFileService.upload(drawing).then((file) => {
                    updatedEquipment = {
                        ...updatedEquipment,
                        drawingUrl: file.url,
                    }
                })
            } /* else not needed */
            if (isChange.file || isChange.pdfPreview) {
                await EquipmentService.updateCustomEquipment(customEquipmentId, { drawingUrl: updatedEquipment.drawingUrl })
            } /* else not needed */
            if (newPartList.current) {
                await EquipmentService.createEquipmentPartList(customEquipmentId, newPartList.current)
            } /* else not needed */
            if (partsList.length > 0) {
                updatedEquipment.partLists = partsList
            } /* else not needed */
            await getEquipmentCallback()
            panzoom.current = null
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setEditEquipmentPartsModalVisible(false)
            setLoading(false)
        }
    }

    const onUpdatePartsListHandler = async (id?: string) => {
        if (id && id !== '') {
            await PartListService.delete(id)
        }

        setActivePartListIndex(0)
        await getEquipmentCallback()
        // await getAssembly()
        panzoom.current = null
    }

    function onClickPlusDocumentHandler() {
        panzoomDocument.current?.zoomIn()
    }

    function onClickMinusDocumentHandler() {
        panzoomDocument.current?.zoomOut()
    }

    function onClickResetDocumentHandler() {
        panzoomDocument.current?.reset()
    }

    function onClickCloseDocumentHandler() {
        setDocumentDetailVisible(false)
        panzoomDocument.current = null
    }

    const addToCart = (data: PartsModel) => {
        const eq: EquipmentCartModel = {
            id: equipment?.id || '',
            name: equipment?.name || '',
            partNumber: equipment?.partNumber || '',
            drawing: equipment?.drawingUrl ? equipment?.drawingUrl : '' || '',
            pdfPreview: equipment?.drawingUrl ? equipment?.drawingUrl : '' || '',
            manufacturer: {
                name: equipment?.manufacturerName || '',
                id: equipment?.manufacturerId || '',
                email: equipment?.manufacturerName || '',
            },
            serialNumber: equipment?.serialNo,
        }
        const newCartData: PartModel = {
            index: data.index,
            no: data.number,
            serialNo: equipment?.serialNo,
            part_no: data.partNumber,
            name: data.name,
            leadTime: data.leadTime,
            pkg: data.pkg,
            uom: data.uom,
            qty: data.quantity,
            vendor: data.brand,
            description: data.description,
            price: data.price,
            adjustedPrice: data.price,
            id: data.id,
            warrantyEnds: equipment?.warrantyEnds || undefined,
            warrantyDuration: equipment?.warrantyDuration,
            warrantyDurationUnit: equipment?.warrantyDurationUnit,
            warrantyStarts: equipment?.warrantyStarts,
            attachments: [],
        }
        const customerInfo = {
            id: equipment.customerId,
            name: equipment.customer?.name!,
        }
        checkoutContext.addPart(eq, newCartData, customerInfo)
    }
    const handleSearchedData = (data: EquipmentModel[]) => {
        setFilteredData(data)
    }

    const getDescription = () => {
        if (!assemblyId && equipment) {
            return <Warranty status={equipment.warrantyStatus} endDate={equipment.warrantyEnds} />
        }
    }

    const getActivitiesCallback = useCallback(async () => {
        try {
            const response = await ActivityService.getAllByEquipmentId(assemblyId || equipmentId!)
            setActivities(response)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
        }
    }, [assemblyId, equipmentId])

    useEffect(() => {
        getActivitiesCallback()
    }, [getActivitiesCallback])

    const onChangeVisibility = async (activityId: string, visibility: boolean) => {
        try {
            setLoading(true)
            const res = await ActivityService.setVisibility(activityId, visibility)
            await getActivitiesCallback()
            toast.success(res.message)
        } catch (error: any) {
            console.error(error.message)
            toast.error(error.message)
        } finally {
            setLoading(false)
        }
    }

    const updateEquipmentVisible = async (equipmentId: string, visible: boolean) => {
        try {
            setLoading(true)
            await EquipmentService.updateCustomEquipment(equipmentId, { isVisible: visible })
            setEquipment({ ...equipment!, isVisible: visible })
            toast.success('Updated successfully!')
        } catch (error: any) {
            console.error(error)
            toast.error(error)
        } finally {
            setLoading(false)
        }
    }

    const setActivityTableColumns = (): HeadCell[] => {
        const sortByNoneColumns: HeadCellType[] = [HeadCellType.Empty, HeadCellType.Title, HeadCellType.Assignee, HeadCellType.Status, HeadCellType.DueDate, HeadCellType.Action]
        const sortByProductColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Product,
            HeadCellType.PartNumber,
            HeadCellType.Quantity,
            HeadCellType.Task,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]
        const sortByEquipmentColumns: HeadCellType[] = [
            HeadCellType.Empty,
            HeadCellType.Equipment,
            HeadCellType.Task,
            HeadCellType.Assignee,
            HeadCellType.Status,
            HeadCellType.DueDate,
            HeadCellType.Action,
        ]

        let output: HeadCell[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = sortByNoneColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Product) {
            output = sortByProductColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            output = sortByEquipmentColumns.map((child) => activityTableColumns?.find((x) => x.type === child) ?? ({} as HeadCell))
        }

        if (partialUpdateModalVisible) {
            return output.filter((item) => ![HeadCellType.Status, HeadCellType.DueDate].includes(item.type))
        }

        return output
    }

    const activityTableDataMapper = (args: ActivityModel[]): ActivityTableDataModel[] => {
        let output: ActivityTableDataModel[] = []

        if (filterState.sortBy.type === SortByType.None) {
            output = args.map((child) => {
                return {
                    isWorkOrder: child.isWorkOrder,
                    recurring: child.isRecurring,
                    title: child.title,
                    customer: child.customer?.name,
                    assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                    status: child.status,
                    dueDate: standardDate(child.dueDate),
                    opportunity: priceFormatter(child.revenueOpportunity || '-'),
                    id: child.id,
                    groupId: child.groupId,
                    isVisibleToCustomer: child.isVisibleToCustomer,
                    description: child.description,
                    type: child.type,
                    attachments: child.attachments,
                    equipments: child.equipments,
                    products: child.products,
                    assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                    assigneeShortName: assigneeShortNameSetter(child.assignee),
                    assigneeFirstName: assigneeFirstNameSetter(child),
                }
            })
        } else if (filterState.sortBy.type === SortByType.Product) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.products.map((product) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        productId: product?.productId ?? '',
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        productName: product?.product?.name ?? '',
                        productPartNumber: product?.product?.partNumber ?? '',
                        productQuantity: product?.quantity ?? 0,
                        customerCompanyName: child.customer.name,
                        equipments: child.equipments,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        } else if (filterState.sortBy.type === SortByType.Equipment) {
            args.forEach((child) => {
                output = [
                    ...output,
                    ...child.equipments.map((equipment) => ({
                        isWorkOrder: child.isWorkOrder,
                        recurring: child.isRecurring,
                        title: child.title,
                        customer: child.customer?.name,
                        assignee: child.assignee && child.assignee.firstName + ' ' + child.assignee.lastName,
                        status: child.status,
                        dueDate: standardDate(child.dueDate),
                        opportunity: priceFormatter(child.revenueOpportunity || '-'),
                        id: child.id,
                        groupId: child.groupId,
                        isVisibleToCustomer: child.isVisibleToCustomer,
                        description: child.description,
                        type: child.type,
                        attachments: child.attachments,
                        customerCompanyName: child.customer.name,
                        equipmentName: equipment?.name ?? '',
                        equipmentId: equipment?.id ?? '',
                        products: child.products,
                        assigneeColor: assigneeColorSetter(uniqueAssigneesWithColors, child),
                        assigneeShortName: assigneeShortNameSetter(child.assignee),
                    })),
                ]
            })
        }

        return output
    }

    const treeViewHandleClick = () => {
        if (equipment?.tree.children?.length) {
            setTreeViewVisible(!treeViewVisible)
        } else {
            setCreateAssemblyModalVisible(true)
        }
    }

    const onClickViewMoreAtPartialUpdateModal = () => {
        setPartialUpdateModalVisible(false)
        setUpdateModalVisible(true)
    }

    const confirmDeletePart = (id: string, partNumber: string) => {
        const isParent: boolean = !!equipment!.partLists[activePartListIndex]?.parts.find((item) => item.kitNo === partNumber)
        confirm({
            modalClassName: 'delete-part-modal',
            modalTitle: 'Delete part?',
            primaryButtonText: 'Delete',
            contentText: isParent ? 'Deleting this part also deletes all the parts attached to it. This action cannot be undone.' : 'Are you sure you would like to proceed?',
            func: async (successCallback, errorCallback) => {
                try {
                    setLoading(true)
                    await PartService.delete(id)
                    getEquipmentCallback()
                    successCallback()
                } catch (error: any) {
                    errorCallback(error)
                } finally {
                    setLoading(false)
                }
            },
        })
    }

    const afterDeletingDrawing = () => {
        setSelectedImageIndex(0)
        searchParams.set('selectedImageIndex', '0')
        setSearchParams(searchParams)
    }

    return (
        <PageWrapper className="manufacturer-customer-equipment-detail-page" breadcrumb={customerId ? breadcrumbForCustomer : breadcrumbForCustomEquipment} headerInfo={equipment?.customer?.name}>
            {loading && <Loading />}
            {equipment && (
                <TechnicianView
                    isQuick
                    onLoading={() => setLoading(true)}
                    onCompleted={() => setLoading(false)}
                    customerId={equipment?.customerId || ''}
                    className="custom-equipment-page"
                    title={equipment.name}
                    customerName={equipment.customer?.name}
                    description={getDescription()}
                    subTitle={
                        <div>
                            <span onClick={() => navigate('/customer/' + equipment.customerId)} className="equipment-type">
                                {equipment.customer?.name}
                            </span>
                            <span className="part-number">
                                Part Number: <b>{equipment?.partNumber}</b>
                            </span>
                        </div>
                    }
                >
                    <ContentBox
                        treeViewMenu={<TreeViewButton isCreate={!equipment?.tree?.children?.length} visible={treeViewVisible} onClickButton={treeViewHandleClick} />}
                        title={<EquipmentTitle equipment={equipment} />}
                        description={
                            (equipment?.customerId !== checkoutContext.cartInformation.purchaseForCustomerId || checkoutContext.cartInformation.technicianViewType === TechnicianViewType.None) &&
                            getDescription()
                        }
                        subTitle={
                            <div>
                                {equipment.customer?.name && (
                                    <span onClick={() => navigate('/customer/' + equipment.customerId)} className="equipment-type">
                                        {equipment.customer?.name}
                                    </span>
                                )}
                                <span className="part-number">
                                    Part Number: <b>{equipment?.partNumber}</b>
                                </span>
                            </div>
                        }
                    >
                        <div className="detail-page-container">
                            <div className="tree-view-box">
                                <TreeView
                                    setSelectedItem={setSelectedEquipmentTreeInfo}
                                    isAbsolute={isDrawingTabHortizalRow || checkoutContext.cartInformation.show || checkoutContext.cartInformation.quickCartShow}
                                    baseId={assemblyId!}
                                    rootBaseId={equipmentId!}
                                    visible={treeViewVisible}
                                    setVisible={() => setTreeViewVisible(false)}
                                    rootBaseURL={'/custom-equipment/'}
                                    root={{ id: equipment.tree?.id, name: equipment.tree?.name }}
                                    baseURL={'/custom-equipment/' + equipment.tree?.id + '/assembly/'}
                                    nodes={equipment.tree?.children}
                                    headerActionButtons={treeviewHeaderActionButtons}
                                    onLoading={() => setLoading(true)}
                                    onCompleted={() => setLoading(false)}
                                    onSuccessCallBack={() => {
                                        getEquipmentCallback()
                                    }}
                                />
                            </div>
                            <div className="detail-page">
                                <div className="tab-container">
                                    <Tab
                                        activeTab={activeTab}
                                        tabs={tabItems.filter((item) => (assemblyId ? item.id !== Tabs.Activities : item))}
                                        onTabClick={(id) => {
                                            setSearchParams({ activeTab: id.toString() })
                                            setActiveTab(id)
                                            setEquipment({ ...equipment, notes: noteRef.current || '' })
                                            setDocumentDetailVisible(false)
                                            panzoom.current = null
                                            panzoomDocument.current = null
                                        }}
                                    />
                                    <div className="visible-switch-container">
                                        <PopoverItem popoverContent={!equipment.isVisible ? 'Hidden from customer' : 'Visible to customer'}>
                                            <Switch
                                                className="switch-button"
                                                checked={equipment.isVisible}
                                                onChange={() => {
                                                    updateEquipmentVisible(equipment.id, !equipment.isVisible)
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        </PopoverItem>
                                    </div>
                                    <div className="divider" />

                                    {activeTab === Tabs.DrawingAndParts && (
                                        <>
                                            <Button
                                                type={isDrawingTabHortizalRow ? ButtonType.Quaternary : ButtonType.Secondary}
                                                className={`toggle-button ${!isDrawingTabHortizalRow && 'disabled'}`}
                                                onClick={() => setIsDrawingTabHortizalRow(!isDrawingTabHortizalRow)}
                                            >
                                                <PopoverItem key="update" popoverContent="Vertical split view">
                                                    <Icons type={IconType.Menus} />
                                                </PopoverItem>
                                            </Button>
                                            <Button
                                                type={!isDrawingTabHortizalRow ? ButtonType.Quaternary : ButtonType.Secondary}
                                                className={`toggle-button ${isDrawingTabHortizalRow && 'disabled'}`}
                                                onClick={() => setIsDrawingTabHortizalRow(!isDrawingTabHortizalRow)}
                                            >
                                                <PopoverItem key="update" popoverContent="Horizontal split view">
                                                    <Icons type={IconType.HortizalRow} />
                                                </PopoverItem>
                                            </Button>
                                            <div className="divider" />
                                            <Button
                                                disabled={isLinkedTemplate && selectedEquipmentTreeInfo.level !== 0}
                                                className="upload-button"
                                                onClick={() => setEditEquipmentDrawingPartsModalVisible(true)}
                                            >
                                                <PopoverItem key="update" popoverContent="Update drawing and parts">
                                                    <Icons type={IconType.Upload} />
                                                </PopoverItem>
                                            </Button>
                                        </>
                                    )}

                                    <div style={{ display: 'flex', gap: '0.3rem' }}>
                                        {activeTab === Tabs.Documents && (
                                            <div className="activity-right-buttons">
                                                <SearchInput data={documents} handleSearch={onDocumentsHandleSearch} searchKeys={['name']} />
                                                <Button className="create-document-button" onClick={() => setCreateDocumentModalVisible(true)}>
                                                    <Icons type={IconType.PlusCircle} />
                                                    <span>Add Document</span>
                                                </Button>
                                            </div>
                                        )}
                                        {activeTab === Tabs.Orders && (
                                            <div className="activity-right-buttons">
                                                <SearchInput data={orders} handleSearch={onOrdersHandleSearch} searchKeys={['customer', 'transactionNumber', 'userName']} />
                                            </div>
                                        )}
                                        {assemblyId ? (
                                            <Button
                                                disabled={isLinkedTemplate && selectedEquipmentTreeInfo.level !== 0}
                                                className="edit-assembly-button"
                                                type={ButtonType.Secondary}
                                                onClick={() => setEditAssemblyModalVisible(!editAssemblyModalVisible)}
                                            >
                                                <FontAwesomeIcon icon={faPencil} />
                                            </Button>
                                        ) : (
                                            <PopoverItem popoverContent="Actions">
                                                <ThreeDotButton actions={rightMenuActionList} />
                                            </PopoverItem>
                                        )}
                                    </div>
                                </div>
                                {activeTab === Tabs.Activities && activities.length > 0 && (
                                    <div className="tab-container">
                                        <Tab
                                            activeTab={activeActivityTab}
                                            tabs={activityTabItems}
                                            onTabClick={(id) => {
                                                setActiveActivityTab(id)
                                            }}
                                        ></Tab>
                                    </div>
                                )}
                                {activeTab === Tabs.Activities && (
                                    <div className="activity-right-buttons">
                                        {activities.length > 0 && (
                                            <>
                                                <SearchInput
                                                    data={activities}
                                                    handleSearch={handleSearchedActivitiesData}
                                                    searchKeys={['title', 'customer.name', 'assignedBy.firstName', 'assignedBy.lastName', 'startDate', 'frequencyUnit', 'type']}
                                                />
                                                <Tooltip title="View Historical Logs">
                                                    <Switch className="historicalLogSwitch" value={viewHistoricalOnes} onChange={() => setViewHistoricalOnes((prevState) => !prevState)} />
                                                </Tooltip>
                                                <Tooltip title="Apply Filters">
                                                    <span>
                                                        <Button className="filter-button" onClick={toggleFilterMenu}>
                                                            <Icons type={IconType.FilterSettings} />
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )}
                                        <Tooltip title="Add new activity">
                                            <span>
                                                <Button className="add-task" onClick={() => setCreateActivityModalVisible(true)}>
                                                    <Icons type={IconType.BoldPlus} /> Add activity
                                                </Button>
                                            </span>
                                        </Tooltip>
                                    </div>
                                )}

                                {activities.length > 0 && showFilter && activeTab === Tabs.Activities && (
                                    <div className="filter-menu">
                                        <BasicDropdown
                                            label="Sort By"
                                            leftIcon={IconType.Checklist}
                                            menuItems={sortByFilterOptions}
                                            onChange={(e) => setFilter(e, FilterKeys.SortBy)}
                                            selectedItem={{ text: filterState.sortBy.text, id: filterState.sortBy.id }}
                                        />
                                        <BasicDropdown
                                            label="Due Date"
                                            leftIcon={IconType.Calendar}
                                            menuItems={dueDateFilterTypes}
                                            onChange={(e) => setFilter(e, FilterKeys.DueDate)}
                                            selectedItem={{ text: filterState.dueDate.text, id: filterState.dueDate.id }}
                                        />

                                        <CheckBoxDropdownInput
                                            disabled={!uniqueAssigneesWithColors.length}
                                            selectedItems={selectedAssigneeIds}
                                            placeholder="Assignee"
                                            listItems={(uniqueAssigneesWithColors || []).map((p) => {
                                                return {
                                                    id: p.assigneeId,
                                                    text: p.assigneeName,
                                                }
                                            })}
                                            dropIcon={true}
                                            onDataChange={(data, allChecked) => {
                                                setSelectedAssigneeIds(data)
                                                setShowAllAsignees(allChecked as boolean)
                                            }}
                                            showAllCheckbox
                                            showCustomInputLabelWhenEmpty
                                            customInputLabel={setAssigneeInputPlaceholder(selectedAssigneeIds, showAllAsignees, uniqueAssigneesWithColors)}
                                        />
                                        <BasicDropdown
                                            label="Status"
                                            leftIcon={IconType.DottedCircle}
                                            menuItems={statusFilterTypes}
                                            onChange={(e) => setFilter(e, FilterKeys.Status)}
                                            selectedItem={{ text: filterState.status.text, id: filterState.status.id }}
                                        />
                                    </div>
                                )}
                                {activeTab === Tabs.Details && (
                                    <div className="content-container">
                                        <EquipmentDetailsTab
                                            isGoToTemplateButton={isLinkedTemplate}
                                            flexWrap={treeViewVisible}
                                            handleEdit={() => (assemblyId ? setEditAssemblyModalVisible(true) : setEditEquipmentModalVisible(true))}
                                            // manufacturer={manufacturer}
                                            isRootEquipment={!assemblyId}
                                            equipment={equipment as EquipmentModel}
                                            //templateEquipmentId={equipment.id === equipment.id ? (equipment as EquipmentModel).id : equipment.id}
                                            onTagsUpdate={onTagsUpdateHandler}
                                            onLoading={() => setLoading(true)}
                                            onCompleted={() => setLoading(false)}
                                            onSuccess={getEquipmentCallback}
                                        />
                                        <NewNotepad id={equipment.id as string} notes={equipment.notes as string} saveNote={saveNoteHandler} />
                                    </div>
                                )}
                                {activeTab === Tabs.DrawingAndParts && (
                                    <div
                                        className={`custom-equipment-drawing-parts-container ${
                                            checkoutContext.cartInformation.show && isDrawingTabHortizalRow ? 'hortizal-row overflow' : isDrawingTabHortizalRow && 'hortizal-row'
                                        }`}
                                    >
                                        <div className="drawing-container">
                                            <DrawingDashboard
                                                customEquipment
                                                equipment={equipment}
                                                addToCart={addToCart}
                                                onLoading={onLoadingCallback}
                                                onCompleted={onCompletedCallback}
                                                onSuccess={onSuccessCallback}
                                                selectedImageIndex={selectedImageIndex}
                                                onClickPreviousImage={onClickPreviousImage}
                                                onClickNextImage={onClickNextImage}
                                            />
                                        </div>
                                        <div className="parts-table-container">
                                            {equipment && equipment.partLists && equipment.partLists.length > 0 ? (
                                                <>
                                                    <div className="parts-table-action-menu">
                                                        <div className={`equipment-detail-select-list-dropdown ${isDrawingTabHortizalRow && 'small-width'}`}>
                                                            <div onClick={() => setEditPartsListNameModalVisible(true)} className="partlist-edit-button">
                                                                <PopoverItem popoverContent="Edit parts list name">
                                                                    <Icons type={IconType.Pen} />
                                                                </PopoverItem>
                                                            </div>
                                                            <BasicDropdown
                                                                label="TYPE"
                                                                menuItems={equipment.partLists.map((partList: EquipmentPartsListModel, index: number) => ({ id: index, text: partList.name }))}
                                                                onChange={(data) => {
                                                                    setActivePartListIndex(data.id)
                                                                }}
                                                                selectedItem={{
                                                                    id: activePartListIndex,
                                                                    text: equipment.partLists[activePartListIndex]?.name,
                                                                }}
                                                            />
                                                            <SearchInput
                                                                multipleData={searchedData}
                                                                data={equipment.partLists[activePartListIndex]?.parts}
                                                                handleSearch={handleSearchedData}
                                                                searchKeys={['name', 'category', 'brand', 'quantity', 'pkg', 'price', 'partNumber']}
                                                            />
                                                        </div>

                                                        <Button
                                                            type={ButtonType.Primary}
                                                            className="addPart-button"
                                                            onClick={() =>
                                                                setCreatePartModalInfo({
                                                                    visible: true,
                                                                    data: equipment.partLists[activePartListIndex],
                                                                })
                                                            }
                                                        >
                                                            <PopoverItem key="add" popoverContent="Add part">
                                                                <Icons type={IconType.PlusCircle} />
                                                            </PopoverItem>
                                                        </Button>
                                                    </div>
                                                    <div className="list-wrapper">
                                                        {equipment && equipment.partLists && equipment.partLists.length > 0 && equipment.partLists[activePartListIndex] ? (
                                                            isDrawingTabHortizalRow ? (
                                                                <PartialPartsTable
                                                                    deletePart={confirmDeletePart}
                                                                    editPart={(data) =>
                                                                        setEditPartModalInfo({
                                                                            visible: true,
                                                                            data: data,
                                                                        })
                                                                    }
                                                                    data={filteredData.sort((a, b) => a.index - b.index)}
                                                                    partListName={
                                                                        filteredData !== equipment.partLists[activePartListIndex].parts ? equipment.partLists[activePartListIndex].name : undefined
                                                                    }
                                                                    addToCart={addToCart}
                                                                />
                                                            ) : (
                                                                <PartsTable
                                                                    deletePart={confirmDeletePart}
                                                                    // editPart={(data) =>
                                                                    //     setEditPartModalInfo({
                                                                    //         visible: true,
                                                                    //         data: data,
                                                                    //     })
                                                                    // }
                                                                    data={filteredData.sort((a, b) => a.index - b.index)}
                                                                    partListName={
                                                                        filteredData !== equipment.partLists[activePartListIndex].parts ? equipment.partLists[activePartListIndex].name : undefined
                                                                    }
                                                                    addToCart={addToCart}
                                                                />
                                                            )
                                                        ) : (
                                                            <NoDataInfo />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <NoDataInfo />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {activeTab === Tabs.Documents &&
                                    (!loading && documents && !documentDetailVisible ? (
                                        <DocumentListTable
                                            technicianViewType={checkoutContext.cartInformation.technicianViewType}
                                            setSearch={(e) => {
                                                if (searchKey === e) {
                                                    setSearchKey('')
                                                } else {
                                                    setSearchKey(e)
                                                }
                                            }}
                                            data={filteredDocuments.map((d) => {
                                                return {
                                                    name: d.name,
                                                    fileType: getFileType(d.url, true),
                                                    onClick: () => {
                                                        // const fileType = getFileType(d.url)
                                                        // if (fileType === UploadFileType.Url) {
                                                        //     window.open(d.url)
                                                        // } else {
                                                        //     setEquipmentDocument(d)
                                                        //     setFileType(getFileType(d.url))
                                                        //     setDocumentDetailVisible(true)
                                                        // }
                                                        window.open(d.url)
                                                    },
                                                    buttons: [
                                                        {
                                                            icon: IconType.Unlink,
                                                            tooltip: 'Unlink',
                                                            onClick: async () => {
                                                                // UNLINK
                                                                confirm({
                                                                    modalClassName: 'unlink-document-modal',
                                                                    modalTitle: 'Unlink document?',
                                                                    titleColor: '#D84315',
                                                                    primaryButtonText: 'Cancel',
                                                                    doesPrimaryButtonSubmit: false,
                                                                    secondaryButtonText: 'Unlink',
                                                                    func: async (successCallback, errCallback) => {
                                                                        try {
                                                                            setLoading(true)
                                                                            await EquipmentService.unlinkEquipmentFromDocument(d.id, equipment?.id!)
                                                                            await getEquipmentCallback()
                                                                            panzoom.current = null
                                                                            panzoomDocument.current = null
                                                                            setDocumentDetailVisible(false)
                                                                            successCallback()
                                                                        } catch (error: any) {
                                                                            errCallback(error)
                                                                        } finally {
                                                                            setLoading(false)
                                                                        }
                                                                    },
                                                                    contentNode: (
                                                                        <div className="modal-line">
                                                                            <span className="warning-text">
                                                                                When you unlink a document from your equipment, all customers who have access to the equipment will no longer be able to
                                                                                see this document.
                                                                                <br />
                                                                                <br />
                                                                                Are you sure you would like to proceed?
                                                                                <br />
                                                                                <br />
                                                                                You could always link the document again if you need to.
                                                                            </span>
                                                                        </div>
                                                                    ),
                                                                })
                                                            },
                                                        },
                                                    ],
                                                }
                                            })}
                                        />
                                    ) : (
                                        equipmentDocument &&
                                        (fileType === UploadFileType.Pdf ? (
                                            <div className="yellow-wrapper">
                                                <PdfViewer file={equipmentDocument.url} fileName={equipmentDocument.name} onClose={onClickCloseDocumentHandler} layout={'scroll'} />
                                            </div>
                                        ) : fileType === UploadFileType.Image ? (
                                            <div className="yellow-wrapper">
                                                <ZoomButton onClickPlus={onClickPlusDocumentHandler} onClickMinus={onClickMinusDocumentHandler} onClickReset={onClickResetDocumentHandler} />

                                                <div style={{ display: 'flex' }} ref={imageContainerDocument}>
                                                    <img src={equipmentDocument.url} alt={equipmentDocument.name} />
                                                </div>
                                            </div>
                                        ) : fileType === UploadFileType.Video ? (
                                            <span>video</span>
                                        ) : (
                                            <span>not supported!</span>
                                        ))
                                    ))}
                                {activeTab === Tabs.Orders && !loading && orders && (
                                    <>
                                        <OrderListTable data={filteredOrders} />
                                    </>
                                )}
                                {activeTab === Tabs.Activities && !loading && activities && (
                                    <div className="activityContent">
                                        <div className={`activity-table ${partialUpdateModalVisible && 'onBox'}`}>
                                            <ActivityListTable
                                                columns={setActivityTableColumns()}
                                                sortKey="dueDate"
                                                highlightedRowId={partialUpdateModalVisible ? selectedActivtyIds.id : ''}
                                                selectedProductId={partialUpdateModalVisible ? selectedProductId : ''}
                                                selectedEquipmentId={partialUpdateModalVisible ? selectedActivityEquipmentId : ''}
                                                onChangeVisibility={onChangeVisibility}
                                                onHandleClick={(id, groupId, isPartial) => {
                                                    if (!isPartial) {
                                                        setUpdateModalVisible(true)
                                                    } else {
                                                        setPartialUpdateModalVisible(true)
                                                    }
                                                    setselectedActivtyIds({ id: id, groupId: groupId })
                                                }}
                                                onClickProductAndEquipment={(productId, equipmentId) => {
                                                    setSelectedProductId(productId)
                                                    setSelectedActivityEquipmentId(equipmentId)
                                                }}
                                                isManufacturer
                                                data={activityTableDataMapper(
                                                    filteredActivitiesData
                                                        .filter((item: ActivityModel) => {
                                                            if (filterState.dueDate.id === ' ') {
                                                                return item
                                                            } else if (filterState.dueDate.text === 'Before Today') {
                                                                return filterState.dueDate.id >= dayjs(item.dueDate)
                                                            }
                                                            return filterState.dueDate.id.isSame(dayjs(item.dueDate), filterState.dueDate.key)
                                                        })
                                                        .filter((item: ActivityModel) => filterAssignees(item, showAllAsignees, selectedAssigneeIds))
                                                        .filter((item: ActivityModel) => (filterState.status.id === ' ' ? item : item.status === filterState.status.id))
                                                        .filter((item: ActivityModel) => !viewHistoricalOnes || (viewHistoricalOnes && item.status === 'Closed'))
                                                        .filter((item) => {
                                                            if (activeActivityTab === Tabs.Service) {
                                                                return item.type === ActivityTypes.Service
                                                            } else if (activeActivityTab === Tabs.ProductReplenishment) {
                                                                return item.type === ActivityTypes.ProductReplensh
                                                            } else if (activeActivityTab === Tabs.Activities) {
                                                                return item.type === ActivityTypes.Ticket
                                                            } else {
                                                                return true
                                                            }
                                                        })
                                                )}
                                            />
                                        </div>
                                        {selectedActivtyIds && partialUpdateModalVisible && (
                                            <div className="partial-update-box">
                                                <PartialUpdateBox
                                                    id={selectedActivtyIds.id}
                                                    visible={!!selectedActivtyIds && partialUpdateModalVisible}
                                                    closeModal={() => {
                                                        setPartialUpdateModalVisible(false)
                                                    }}
                                                    onLoading={() => null}
                                                    onCompleted={onCompletedCallback}
                                                    onSuccess={async () => await getActivitiesCallback()}
                                                    onError={onErrorCallback}
                                                    onViewMore={onClickViewMoreAtPartialUpdateModal}
                                                />
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </ContentBox>
                </TechnicianView>
            )}

            {selectedActivtyIds && updateModalVisible && (
                <UpdateActivityPopupSidebar
                    id={selectedActivtyIds.id}
                    groupId={selectedActivtyIds.groupId}
                    onChangeId={(id) => setselectedActivtyIds({ ...selectedActivtyIds, id: id })}
                    visible={updateModalVisible}
                    onClose={async () => {
                        setUpdateModalVisible(false)
                        await getActivitiesCallback()
                    }}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={async () => getActivitiesCallback()}
                    onError={onErrorCallback}
                />
            )}
            {createActivityModalVisible && (
                <AddActivityPopupSidebar
                    customerId={equipment!.customerId}
                    equipmentId={equipment!.id}
                    onLoading={onLoadingCallback}
                    onSuccess={async () => await getActivitiesCallback()}
                    onError={onErrorCallback}
                    onCompleted={onCompletedCallback}
                    visible={createActivityModalVisible}
                    onClose={() => setCreateActivityModalVisible(false)}
                />
            )}

            {equipment && editEquipmentPartsModalVisible && (
                <EditEquipmentPartStoreModal
                    isAssembly={selectedEquipmentId.current !== equipment.id}
                    equipment={equipment as EquipmentModel}
                    drawingFileName={equipment && equipment.drawingUrl && equipment.drawingUrl.length > 0 ? (drawingType === DrawingType.Image ? 'drawing.png' : 'drawing.pdf') : ''}
                    csvFileName={equipment && equipment.partLists && equipment.partLists.length > 0 ? 'partsList.csv' : ''}
                    visible={editEquipmentPartsModalVisible}
                    closeModal={() => {
                        setEditEquipmentPartsModalVisible(false)
                    }}
                    submit={updateCustomEquipmentPartStoreHandler}
                    onUpdatePartsList={onUpdatePartsListHandler}
                />
            )}

            {linkDocumentModalVisible && (
                <LinkDocumentModal
                    equipment={equipment as EquipmentModel}
                    selectedIds={equipment?.documents?.map((d: any) => d.id)}
                    visible={linkDocumentModalVisible}
                    close={() => setLinkDocumentModalVisible(false)}
                    onSave={linkDocumentHandler}
                />
            )}

            {createPartModalInfo && createPartModalInfo.data && (
                <CreatePartModal
                    partListName={createPartModalInfo.data.name}
                    partListId={createPartModalInfo.data.id || ''}
                    visible={createPartModalInfo.visible}
                    closeModal={() => setCreatePartModalInfo({ visible: false, data: undefined })}
                    onLoading={() => setLoading(true)}
                    onCompleted={() => setLoading(false)}
                    onSuccess={() => getEquipmentCallback()}
                    onError={(error) => toast.error(error.message)}
                />
            )}
            {editPartModalInfo && editPartModalInfo.data && (
                <EditPartModal
                    data={editPartModalInfo.data}
                    visible={editPartModalInfo.visible}
                    closeModal={() => setEditPartModalInfo({ visible: false, data: undefined })}
                    onLoading={() => setLoading(true)}
                    onCompleted={() => setLoading(false)}
                    onSuccess={() => getEquipmentCallback()}
                    onError={(error) => toast.error(error.message)}
                />
            )}
            {editEquipmentModalVisible && (
                <EditCustomEquipmentModal
                    equipment={equipment!}
                    // parent={parentRef.current || ({} as ParentModel)}
                    visible={editEquipmentModalVisible}
                    closeModal={() => setEditEquipmentModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                />
            )}

            {editEquipmentDrawingPartsModalVisible && (
                <UploadDrawingPartsModal
                    equipment={equipment}
                    visible={editEquipmentDrawingPartsModalVisible}
                    closeModal={() => setEditEquipmentDrawingPartsModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={async (actionType?: CommonActionTypes) => {
                        if (actionType === CommonActionTypes.DELETE) {
                            afterDeletingDrawing()
                        }
                        await getEquipmentCallback()
                    }}
                    onError={onErrorCallback}
                />
            )}

            {createAssemblyModalVisible && (
                <CreateAssemblyModal
                    parentList={parentList}
                    equipment={equipment}
                    rootEquipmentId={equipmentId!}
                    parent={parentRef.current || ({} as ParentModel)}
                    visible={createAssemblyModalVisible}
                    closeModal={() => setCreateAssemblyModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                />
            )}

            {editAssemblyModalVisible && (
                <EditCustomEquipmentAssemblyModal
                    isRootEquipmentId={equipmentId!}
                    isLinkedTemplate={isLinkedTemplate}
                    parentList={parentList}
                    isHubspotSupport={false}
                    equipment={equipment!}
                    assemblyId={assemblyId!}
                    parentId={selectedEquipmentTreeInfo.parentId || ''}
                    visible={editAssemblyModalVisible}
                    closeModal={() => setEditAssemblyModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={onSuccessCallback}
                    onError={onErrorCallback}
                />
            )}

            {editAssemblyDrawingPartsModalVisible && assembly && (
                <UploadDrawingPartsModal
                    equipment={assembly}
                    visible={editAssemblyDrawingPartsModalVisible}
                    closeModal={() => setEditAssemblyDrawingPartsModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onSuccess={async (actionType?: CommonActionTypes) => {
                        if (actionType === CommonActionTypes.DELETE) {
                            afterDeletingDrawing()
                        }
                        await getEquipmentCallback()
                    }}
                    onError={onErrorCallback}
                />
            )}

            {pushEquipmentToCustomModalVisible && (
                <PushEquipmentToCustomModal
                    isMoleaerUser={equipment.manufacturerId === '140542f7-a3bc-40ca-879e-176a0a223b11'}
                    type="assembly"
                    warningTitle={
                        equipment.manufacturerId
                            ? '(Warning: this will overwrite sub-assemblies, part-lists, parts, and documents of custom equipment and replace them to match this template. This cannot be undone).'
                            : '(Warning: this will overwrite all custom equipment completely and replace them to match this template.This cannot be undone).'
                    }
                    id={equipment!.id}
                    visible={pushEquipmentToCustomModalVisible}
                    closeModal={() => setPushEquipmentToCustomModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onError={onErrorCallback}
                />
            )}
            {createDocumentModalVisible && (
                <EquipmentCreateDocumentModal
                    id={assemblyId! ?? equipmentId}
                    visible={createDocumentModalVisible}
                    documents={equipment?.documents!}
                    closeModal={() => setCreateDocumentModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onError={onErrorCallback}
                    onSuccess={() => getEquipmentCallback()}
                />
            )}
            {editPartsListNameModalVisible && equipment.partLists[activePartListIndex] && (
                <EditPartsListModal
                    id={equipment.partLists[activePartListIndex].id!}
                    name={equipment.partLists[activePartListIndex].name}
                    visible={editPartsListNameModalVisible}
                    closeModal={() => setEditPartsListNameModalVisible(false)}
                    onLoading={onLoadingCallback}
                    onCompleted={onCompletedCallback}
                    onError={onErrorCallback}
                    onSuccess={() => getEquipmentCallback()}
                />
            )}
        </PageWrapper>
    )
}

export default CustomerEquipmentDetailForManufacturer
