import { Tooltip } from '@mui/material'
import React, { FC, Fragment, ReactNode, useContext, useState } from 'react'
// import PdfViewer from '../../../../../../../components/PdfViewer'
// import { useNavigate } from 'react-router'
import Icons from '../../../../../../../Icon'
import Icon, { IconType } from '../../../../../../../Icon'
import { SearchPartsTableColumnModel, SearchPartsTableColumns } from '../../../searchParts.types'
import './searchPartsTableSubRow.scss'
import PdfViewer from '../../../../../../../components/PdfViewer'
import { priceFormatter } from '../../../../../../../utils/helpers'
import { DrawingType, EquipmentCartModel } from '../../../../../../../models/Ui/Equipment'
import { useNavigate } from 'react-router-dom'
import { GetPartDto } from '../../../../../../../models/Ui/Part'
import { Color } from '../../../../../../../models/enums'
import { CheckoutContext } from '../../../../../../../store/NewCheckoutContext/CheckoutContext'
import { PartModel } from '../../../../../../../models/Db/Equipment'
import { TechnicianViewType } from '../../../../../../../store/models'
import Button from '../../../../../../../components/Button'

interface SearchPartsTableSubRowProps {
    columns: SearchPartsTableColumnModel[]
    isLastOne: boolean
    data: GetPartDto
}

const SearchPartsTableSubRow: FC<SearchPartsTableSubRowProps> = ({ columns, isLastOne, data }) => {
    const navigate = useNavigate()
    const [showDrawings, setShowDrawings] = useState(false)
    const checkoutContext = useContext(CheckoutContext)
    const equipment = checkoutContext.contextEquipments.find((contextEquipment) => contextEquipment.equipment.id === data.equipment.id)

    const onClickChevronUpDown = () => {
        setShowDrawings((prevState) => !prevState)
    }

    const onClickChevronRight = () => {
        navigate({
            pathname: data.customer ? '/custom-equipment/' + data.equipment.id : '/equipment/' + data.equipment.id,
            search: '?action=1',
        })
    }

    const addToCart = (data: GetPartDto) => {
        const eq: EquipmentCartModel = {
            id: data.equipment.id,
            name: data.equipment.name,
            partNumber: data.equipment.partNumber,
            drawing: data.equipment.drawingUrl,
            pdfPreview: data.equipment.drawingUrl,
            manufacturer: data.manufacturer,
            serialNumber: data.equipment.serialNo,
        }
        const newCartData: PartModel = {
            index: data.index,
            no: data.number,
            serialNo: data.equipment.serialNo,
            part_no: data.partNumber,
            name: data.name,
            leadTime: data.leadTime,
            pkg: data.pkg,
            uom: data.uom,
            qty: data.quantity,
            vendor: data.brand,
            description: data.description,
            price: data.price,
            adjustedPrice: data.price,
            id: data.id,
            warrantyEnds: data.equipment.warrantyEnds,
            warrantyDuration: data.equipment.warrantyDuration,
            warrantyDurationUnit: data.equipment.warrantyDurationUnit,
            warrantyStarts: data.equipment.warrantyStarts,
            attachments: [],
        }
        checkoutContext.addPart(eq, newCartData)
    }

    const enabledAddToCart = () => checkoutContext.cartInformation.purchaseForCustomerId || checkoutContext.purchaseWithoutCustomer

    let drawingType = DrawingType.Image
    if (data?.equipment.drawingUrl) drawingType = new URL(data!?.equipment.drawingUrl!).pathname.toLowerCase().endsWith('.pdf') ? DrawingType.Pdf : DrawingType.Image

    const renderColumnCells = (column: SearchPartsTableColumns): ReactNode => {
        switch (column) {
            case SearchPartsTableColumns.PartName:
                return (
                    <div style={{ float: 'right' }} className="partNameContainer searchedEquipmentClass">
                        <Tooltip title={data.equipment.drawingUrl ? (showDrawings ? 'Hide Drawing' : 'Preview Drawing') : 'No Drawing Available'}>
                            <div className={data.equipment.drawingUrl ? `chevronSection ${showDrawings && 'activeChevronSection'}` : 'disableChevronSection'} onClick={onClickChevronUpDown}>
                                {data.equipment.drawingUrl ? <Icons type={showDrawings ? IconType.ChevronUp : IconType.ChevronDown} /> : <Icons type={IconType.ChevronDown} color={Color.grey100} />}
                            </div>
                        </Tooltip>
                        {checkoutContext.quickCartInformation.id ? (
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div className="partName">
                                    <span className={data.equipment.drawingUrl ? `partNameText ${showDrawings && 'buttomBordered'}` : 'partNameText'}>{data?.name ?? ''}</span>
                                </div>
                                <span className="container-sub-item">
                                    {data.partNumber}
                                    {showDrawings ? ' (Ref. no: ' + data.number + ')' : ''}
                                </span>
                            </div>
                        ) : (
                            <div className="partName">
                                <div className={data.equipment.drawingUrl ? `partNameText ${showDrawings && 'buttomBordered'}` : 'partNameText'}>{data?.name ?? ''}</div>
                            </div>
                        )}
                    </div>
                )

            case SearchPartsTableColumns.EquipmentName:
                return checkoutContext.quickCartInformation.id ? (
                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '22rem' }}>
                        <div className={data.equipment.drawingUrl ? `customerContainer ${showDrawings && 'buttomBordered'}` : 'customerContainer'}>
                            <span className="companyName">{data?.equipment.name ?? ''}</span>
                        </div>
                        <span className="container-sub-item">{data.equipment.serialNo}</span>
                    </div>
                ) : (
                    <div className={data.equipment.drawingUrl ? `customerContainer ${showDrawings && 'buttomBordered'}` : 'customerContainer'}>
                        <span className="companyName">{data?.equipment.name ?? ''}</span>
                    </div>
                )

            case SearchPartsTableColumns.Price:
                return (
                    <div
                        className={data.equipment.drawingUrl ? `priceContainer ${showDrawings && 'buttomBordered'}` : 'priceContainer'}
                        style={
                            checkoutContext.cartInformation.purchaseForCustomerId
                                ? {
                                      justifyContent: 'flex-start',
                                      paddingLeft: '1rem',
                                  }
                                : {}
                        }
                    >
                        {data?.price ? priceFormatter(data.price) : ''}
                    </div>
                )

            case SearchPartsTableColumns.Action:
                let isAddedCart
                if (equipment) {
                    isAddedCart = equipment.parts.some((contextPart) => contextPart.part.id === data.id)
                }
                return (
                    <div className="action-container">
                        {checkoutContext.cartInformation.technicianViewType === TechnicianViewType.Quick && (
                            <>
                                {isAddedCart ? (
                                    <span className="green-tick" style={{ width: '100%' }}>
                                        <Icon type={IconType.GreenTick} color={Color.green900} />
                                    </span>
                                ) : (
                                    <Tooltip title="Add to cart">
                                        <Button
                                            className={`addToCart ${!enabledAddToCart() && 'disabled'}`}
                                            onClick={() => {
                                                if (enabledAddToCart()) {
                                                    addToCart(data)
                                                }
                                            }}
                                        >
                                            <Icon type={IconType.AddToCart} />
                                        </Button>
                                    </Tooltip>
                                )}
                            </>
                        )}

                        <Tooltip title="View Equipment">
                            <div className={data.equipment.drawingUrl ? `actionContainer ${showDrawings && 'buttomBordered'}` : 'actionContainer'} onClick={() => onClickChevronRight()}>
                                <Icons type={IconType.ChevronRight} />
                            </div>
                        </Tooltip>
                    </div>
                )

            case SearchPartsTableColumns.PartNumber:
                return (
                    !checkoutContext.quickCartInformation.id && (
                        <div style={{ display: 'flex', height: '100%', alignItems: 'center' }}>
                            <div className="customerContainer">
                                <span className="companyName">{data?.partNumber ?? ''}</span>
                            </div>
                        </div>
                    )
                )
            default:
                return <Fragment />
        }
    }

    return (
        <Fragment>
            <div className="searchPartsTableSubRowContainer">
                {columns.map((column) => (
                    <div style={{ width: column.width, cursor: '' }} className="subRowColumn">
                        {renderColumnCells(column.columnType)}
                    </div>
                ))}
            </div>

            {data.equipment.drawingUrl && showDrawings && (
                <div className="searchPartsDrawingContainer">
                    <div className="yellow-wrapper drawing-parts">
                        <div className="drawingContent">
                            {drawingType === DrawingType.Pdf ? (
                                <>
                                    <div className="lineContainer">{!isLastOne && <div className="verticalLine"></div>}</div>
                                    <div style={{ position: 'relative', width: '100%', height: '100px' }}></div>
                                    <PdfViewer className="pdf-viewer-sm" file={data?.equipment.drawingUrl ?? ''} fileName={data?.equipment.drawingUrl ?? ''} type={'sm'} />
                                </>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <img src={data?.equipment.drawingUrl} alt={data.name} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

export default SearchPartsTableSubRow
